// With Ref

// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

// NoCode Engine © 2023
// v0.1.3
import { addPropertyControls, ControlType } from "framer"
import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
import React, { useEffect, useState, useRef } from "react"
import $ from "jquery"

enum PaginationType {
    NextPrevious = 1,
    LoadMore = 2,
}

interface Props {
    limit: number
    paginationType: PaginationType
}

interface PageMeta {
    start: number
    end: number
}

const [
    NAV_CONTAINER,
    NAV_NEXT_PAGE,
    NAV_PREV_PAGE,
    LOADING_VIEW,
    PAGINATION_ITEMS,
    NAV_PAGE_NUMBER,
] = [
    "nc-paginate-nav",
    "nc-next-page",
    "nc-prev-page",
    "nc-page-loader",
    "nc-item",
    "nc-page-number",
]

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth 0%
 * @framerSupportedLayoutHeight 0%
 */
export default function Paginator(props: Props) {
    const [width, setWindowWidth] = useState(0)
    useEffect(() => {
        updateDimensions()
        window.addEventListener("resize", updateDimensions)
        return () => window.removeEventListener("resize", updateDimensions)
    }, [])
    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const [displaySelf, setDisplaySelf] = useState<boolean>(false)
    const page = useRef<number>(1)
    const totalPages = useRef<number>(0)
    const [pageNumber, setPageNumber] = useState<number>(1)

    const toggleDisplay = (displayContent: boolean = false) => {
        if (displayContent) {
            $(`.${LOADING_VIEW}`).css("display", "none")
        }

        $(`.${NAV_CONTAINER}`).css("display", displayContent ? "block" : "none")

        setDisplaySelf(displayContent)
    }

    const paginate = (limit, elems: JQuery<HTMLElement>, pageNum = 1): void => {
        const prevRange = (pageNum - 1) * limit
        const currRange = pageNum * limit

        if (props.paginationType === PaginationType.NextPrevious) {
            elems
                .slice(page.current - 1, page.current * limit)
                .css("display", "none")
        }

        elems.slice(prevRange, currRange).css("display", "block")
    }

    useEffect(() => {
        $(`.${NAV_PAGE_NUMBER}`)
            .contents()
            .first()
            .text(`${pageNumber}/${totalPages.current}`)

        if (totalPages.current !== 0 && pageNumber === totalPages.current) {
            $(`.${NAV_NEXT_PAGE}`).hide()
        } else {
            $(`.${NAV_NEXT_PAGE}`).show()
        }

        if (pageNumber === 1) {
            $(`.${NAV_PREV_PAGE}`).hide()
        } else {
            $(`.${NAV_PREV_PAGE}`).show()
        }
    }, [pageNumber])

    useEffect(() => {
        if (window) {
            try {
                const elems = $(`.${PAGINATION_ITEMS}`)
                totalPages.current = Math.ceil(elems.length / props.limit)

                $(`.${NAV_PAGE_NUMBER}`)
                    .contents()
                    .first()
                    .text(`${page.current}/${totalPages.current}`)

                $(`.${NAV_NEXT_PAGE}`).on("click", (e) => {
                    const pageNum =
                        page.current + 1 > totalPages.current
                            ? totalPages.current
                            : page.current + 1
                    paginate(props.limit, elems, pageNum)
                    if (page.current > page.current) {
                        return
                    }
                    page.current += 1
                    setPageNumber(page.current)
                })

                if (props.paginationType === PaginationType.NextPrevious) {
                    $(`.${NAV_PREV_PAGE}`).on("click", (e) => {
                        const pageNum =
                            page.current - 1 < 1 ? 1 : page.current - 1
                        paginate(props.limit, elems, pageNum)
                        if (page.current === 1) {
                            return
                        }
                        page.current -= 1
                        setPageNumber(page.current)
                    })
                }

                toggleDisplay(false)

                // Hide all elements and only show those we need
                elems.css("display", "none")

                elems.slice(0, props.limit).css("display", "block")

                toggleDisplay(true)

                return () => {
                    $(`.${NAV_NEXT_PAGE}`).off()
                    $(`.${NAV_PREV_PAGE}`).off()
                }
            } catch (e) {
                // console.log(e)
                // There is a linting error for .addEventListener, this catches it till we have a proper solution
            }
        }
    }, [])

    return (
        <div
            id="nc-paginator"
            style={{
                ...containerStyle,
            }}
        ></div>
    )
}

Paginator.displayName = "Paginator"

Paginator.defaultProps = {
    limit: 1,
    paginationType: PaginationType.NextPrevious,
}

addPropertyControls(Paginator, {
    limit: {
        type: ControlType.Number,
        defaultValue: 1,
        step: 1,
        displayStepper: true,
    },
    paginationType: {
        type: ControlType.Enum,
        defaultValue: PaginationType.NextPrevious,
        displaySegmentedControl: true,
        options: [PaginationType.NextPrevious, PaginationType.LoadMore],
        optionTitles: ["Next/Previous", "Load More"],
        segmentedControlDirection: "vertical",
    },
})

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    display: "flex",
    //justifyContent: "center",
}
